import React, { useEffect } from 'react';

import 'assets/styles/pages/faq.scss';

import AuthLogin from 'components/admin/login';
import { navigate } from 'gatsby';

const AdminLogin = () => {
  useEffect(() => {
    const uDetails = (typeof window !== 'undefined' && window.localStorage) ? localStorage.getItem("hfnAuthUserDetailsAdmin") : null
    if (uDetails !== null && uDetails !== "") {
      navigate("/admin/dashboardview")
    } else {
      navigate("/admin")
    }
  }, []);
  return (
    <>
      <AuthLogin name="Admin Login"
        onSignedIn={userDetails => {
          navigate("/admin/dashboardview")
        }}
        signInBtnText="Admin Login"
      />
    </>)
}
export default AdminLogin;